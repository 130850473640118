import React, { useEffect, useRef } from "react";

import X from "@components/icons/x.jsx";

const Modal = ({ children, size, onClose }) => {
    // no scrolling
    useEffect(() => {
        const doc = document.documentElement;
        let overflow = doc.style.overflow;
        let paddingRight = doc.style.paddingRight;

        let scrollbarWidth =
            window.innerWidth - doc.clientWidth;

        doc.style.overflow = "hidden";
        doc.style.paddingRight = `${scrollbarWidth}px`;

        return () => {
            doc.style.overflow = overflow;
            doc.style.paddingRight = paddingRight;
        };
    }, []);

    // close when clicked outside of modal
    const modalRef = useRef();
    useEffect(() => {
        const listener = evt => {
            if (modalRef.current &&
                !modalRef.current.contains(evt.target)) {
                onClose();
            }
        };
        window.addEventListener("mousedown", listener)
        return () => window.removeEventListener("mousedown", listener);
    }, []);

    return (
        <div
            className="bg-black/50 backdrop-blur-sm py-3 fixed inset-0 z-[999]"
        >
            <div 
                ref={modalRef}
                onClick={(e) => e.stopPropagation()}
                className={`${size === "full" ? "mx-4 h-full" : "max-w-sm mx-auto"} p-4 ring-0 ring-stone-800 drop-shadow-lg rounded-xl bg-stone-200 relative`}
            >
                <button
                    onClick={onClose}
                    className="absolute right-4 p-1 rounded-full ring-0 w-7 h-7 ring-emerald-600 text-emerald-700 hover:bg-emerald-200 bg-emerald-100/80 drop-shadow-md"
                >
                    <X className="h-5 w-5" strokeWidth="2" />
                </button>

                {children}
            </div>
        </div>
    );
};

export default Modal;
