import React from "react";

const DateFormatter = ({value}) => {
    if (!(value instanceof Date)) {
        value = new Date(Date.parse(value));
    }
    if (!value) return "";

    const aYearAgo = new Date();
    aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);

    if (value < aYearAgo) {
        return value.toLocaleDateString(undefined, {
            month: "short",
            year: "numeric",
        });
    } else {
        return value.toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
            year: "numeric",
        });
    }
};

export default DateFormatter;