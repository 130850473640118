import React, { useState, useEffect } from "react";

import { get, post, put, del } from "@hooks/backend.js";

const _sanitize = (val) => {
    // simple sanitization. won't handle nested elements.
    return val?.replace(/\<[^>]+\>(.*?)\<\/[^>]+\>/gm, "$1");;
}
const sanitize = (recipe) => {
    const sanitized = {
        ...recipe,
        ingredients: recipe.ingredients?.map(_sanitize),
        directions: recipe.directions?.map(_sanitize)
    }
    return sanitized;
}

const useRecipe = (recipeId) => {
    const [recipe, setRecipe] = useState();

    useEffect(() => {
        setRecipe(null);
        if (recipeId) {
            get(`/recipes/${recipeId}`)
                .then(r => setRecipe(sanitize(r)))
                .catch(() => setRecipe("NOT_FOUND"));
        }
    }, [recipeId]);

    const actions = {
        add: (recipe) => {
            return post("/recipes/", recipe);
        },
        update: (recipe) => {
            if (recipe._id !== recipeId) throw "Wrong update id";
            return put(`/recipes/${recipeId}`, recipe).then((recipe) => {
                setRecipe(recipe);
                return recipe;
            });
        },
        delete: () => {
            return del(`/recipes/${recipeId}`).then(() => setRecipe(null));
        },
    };

    return {
        recipe: recipe === "NOT_FOUND" ? null : recipe,
        actions,
        notFound: recipe === "NOT_FOUND",
    };
};
export default useRecipe;

export const useRecipes = ({ search, group, start, count }) => {
    const [result, setResult] = useState();

    useEffect(() => {
        setResult(null);
        get("/recipes/", { search, group, start, count }).then(setResult);
    }, [search, group, start, count]);

    const actions = {
        update: (recipe) => {
            return put(`/recipes/${recipe._id}`, recipe);
        },
    };

    return { recipes: result, actions };
};
