import React, { useState } from "react";

import useUser from "@hooks/useUser.js";

const ResetPage = ({}) => {
    const { actions } = useUser();
    const [status, setStatus] = useState();

    const [email, setEmail] = useState("");

    const reset = () => {
        setStatus("RESETTING");
        actions.resetPassword(email).then(() => setStatus("SENT"));
    };

    return (
        <div className="max-w-xs mx-auto mt-4">
            <h3 className="font-brand border-b border-b-emerald-700/20 font-semibold text-xl mb-4">
                Reset Your Password
            </h3>

            <label className="block mb-2 font-light">
                Email
                <input
                    autoComplete="email"
                    className="w-full block px-2 py-1 rounded-md border-0 drop-shadow-sm outline-emerald-600 border-stone-800 invalid:bg-red-100 invalid:border-red-700 focus:invalid:outline-red-700 invalid:text-red-700"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </label>

            <div className="mt-3 flex justify-between">
                <button
                    onClick={reset}
                    disabled={!email.trim()}
                    className="rounded-lg bg-emerald-700 disabled:bg-emerald-700/60 hover:bg-emerald-900 text-emerald-100 p-3 inline-block ring-0 ring-emerald-50 drop-shadow-md"
                >
                    Reset
                </button>
            </div>

            {status === "SENT" && (
                <p className="bg-white max-w-sm mx-auto my-7 p-4 rounded-xl outline outline-0 outline-stone-500 font-light">
                    Check your email for instructions how to reset your
                    password.
                </p>
            )}
        </div>
    );
};

export default ResetPage;
