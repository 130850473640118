import React from "react";

const ChevronDown = ({ className = "h-4 w-4", strokeWidth = 1 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={strokeWidth}
                d="M19 9l-7 7-7-7"
            />
        </svg>
    );
};
export default ChevronDown;
