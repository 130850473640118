import React from "react";
import PropTypes from "prop-types";

import { BASE_URL } from "@hooks/backend.js";

const Image = React.forwardRef(({src, ...others}, ref) => {
    if (src?.startsWith("/")) src = BASE_URL + "/files" + src;
    return <img ref={ref} {...others} src={src}/>;
});
Image.propTypes = {
    src: PropTypes.string.isRequired
};

export default Image;