import React from "react";

const Reply = ({ className = "h-4 w-4", strokeWidth = 1 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={strokeWidth}
                d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6"
            />
        </svg>
    );
};
export default Reply;
