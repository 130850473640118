import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import App from "./app.jsx";

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
