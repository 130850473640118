import React from "react";

import Modal from "@components/modal.jsx";

const ConfirmModal = ({
    children,
    confirm = "Yes",
    cancel,
    onConfirm,
    onClose,
}) => {
    return (
        <Modal onClose={onClose}>
            <div className="max-w-xs mx-auto mb-2">
                {children}

                <div className="mt-3 flex justify-between">
                    <button
                        onClick={onConfirm}
                        className="rounded-lg bg-emerald-700 hover:bg-emerald-900 text-emerald-100 px-3 py-2 inline-block ring-0 ring-emerald-50 drop-shadow-md"
                    >
                        {confirm}
                    </button>
                    {cancel && (
                        <button
                            onClick={onClose}
                            className="rounded-lg bg-emerald-100/80 hover:bg-emerald-200 text-emerald-700 px-3 py-2 inline-block ring-0 ring-emerald-600 drop-shadow-md"
                        >
                            {cancel}
                        </button>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;
